import { AnalyticsBrowser } from '@segment/analytics-next'
import { Organization, UserRole } from '../../../graphql/@types/schema'

let analytics: AnalyticsBrowser | null = null

type TrackPagePayload = {
  path: string
  url: string
  organization: Organization
  currentUser?: SegmentUserData
}

type IdentifyPayload = {
  user: SegmentUserData
  organization: SegmentOrganizationData
}

type GroupPayload = {
  userId: string
  organization: SegmentOrganizationData
}

type IdentifyAndGroupPayload = {
  user: SegmentUserData
  organization: SegmentOrganizationData
}

type SegmentUserData = {
  id: string
  name: string
  phone?: string | null
  email?: string | null
  role: UserRole
  detail?: string | null
}

type SegmentOrganizationData = {
  id: string
  name: string
  subdomain: string
}

try {
  const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY || ''
  if (writeKey != '' && process.env.REACT_APP_TARGET_ENV === 'production') {
    analytics = AnalyticsBrowser.load({ writeKey })
  }
} catch (error) {
  console.error('Error loading analytics', error)
}

export const trackPage = (properties: TrackPagePayload) => {
  const { path, url, organization, currentUser } = properties
  // only track page views for authenticated users
  if (currentUser != null && currentUser.id != null && analytics != null) {
    try {
      analytics.page(path, {
        path,
        url,
        properties: {
          $groups: {
            company: organization.id,
          },
        },
        context: {
          groupId: organization.id,
          traits: {
            name: organization.name,
            subdomain: organization.subdomain,
          },
        },
        userId: currentUser.id,
      })
    } catch (error) {
      console.warn('Failed to track page view:', error)
    }
  }
}

export const trackEvent = (event: string, properties?: Record<string, unknown>) => {
  try {
    analytics?.track(event, properties)
  } catch (error) {
    console.warn('Failed to track event:', error)
  }
}

const getUserIdentifyTraits = (user: SegmentUserData) => {
  const [firstName, lastName] = user.name.split(' ')

  return {
    userId: user.id,
    traits: {
      id: user.id,
      email: user.email,
      firstName,
      lastName,
      role: user.role,
      phone: user.phone ?? '',
      title: user.detail ?? '',
    },
  }
}

const identify = (properties: IdentifyPayload) => {
  try {
    const { user, organization } = properties
    const { userId, traits } = getUserIdentifyTraits(user)
    if (analytics != null) {
      analytics.identify(user.id, {
        userId,
        traits,
        context: {
          $groups: {
            company: organization.id,
          },
        },
      })
    }
  } catch (error) {
    console.warn('Failed to identify:', error)
  }
}

const group = (properties: GroupPayload) => {
  const { userId, organization } = properties
  try {
    if (analytics != null) {
      analytics.group(organization.id, {
        userId,
        groupId: organization.id,
        id: organization.id,
        traits: {
          name: organization.name,
          subdomain: organization.subdomain,
          id: organization.id,
          user_friendly_name: organization.name,
        },
        context: {
          $groups: {
            company: organization.id,
          },
        },
      })
    }
  } catch (error) {
    console.warn('Failed to group:', error)
  }
}

export const identifyAndGroup = async (properties: IdentifyAndGroupPayload) => {
  const { user, organization } = properties
  // only identify and group authenticated users
  if (user != null && user.id != null) {
    await identify({ user, organization })
    await group({ userId: user.id, organization })
  }
}

export default analytics
