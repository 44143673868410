import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackPage, identifyAndGroup } from './analytics'
import useCurrentOrganization from '../../hooks/auth/useCurrentOrganization'
import useCurrentUser from '../../hooks/auth/useCurrentUser'

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation()
  const { currentOrganization } = useCurrentOrganization()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser != null && currentOrganization != null) {
      identifyAndGroup({
        user: currentUser,
        organization: currentOrganization,
      })
    }
  }, [currentUser, currentOrganization])

  useEffect(() => {
    if (currentOrganization != null) {
      trackPage({
        path: location.pathname,
        url: window.location.href,
        organization: currentOrganization,
        currentUser,
      })
    }
  }, [location.pathname, currentOrganization, currentUser])

  return <>{children}</>
}

export default AnalyticsProvider
